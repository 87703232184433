




































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import moment from 'moment'
import Screen from './screen.vue'
import FengMap from '@/components/common/FengMap/index.vue'
import { formatDateStart, formatDateEnd, getDuration, timeType } from '@/utils'
import pageTitle from '@/components/common/pageTitle.vue'
import TimePlay from '@/components/common/Play/timePlay.vue'
import { variables } from '@/libs/theme'
@Component({
  name: 'index',
  components: {
    Screen,
    FengMap,
    pageTitle,
    TimePlay
  }
})
export default class DataMap extends Vue {
  @Ref() readonly fengmapNode: Vue
  private shadow9Color = variables.transparent
  tabIndex = 0
  mapHeight = 750
  mapLoaded = false
  apiLoaded = true
  tabList = [
    {
      name: '热力分析',
      index: 0
    },
    {
      name: '空间眷恋',
      index: 1
    },
    {
      name: '群体动线',
      index: 2
    }
  ]
  children: any[] = []
  intervalDays = 1
  span = 'hour'
  isShowHour = true
  speed = 500
  animateTime: number | null = null
  groupLine: any[] = []
  thermalData: any[] = []
  heating: any[] = []
  residenceTime: any[] = []
  isSuccessLoad = false
  timer: any = 0
  showMap = false
  message: any
  form: AnyObj = {
    startDate: new Date(),
    endDate: new Date()
  }
  dateAreasMap: AnyObj = {}
  domPosition = {
    button: { x: 0, y: 180, type: 'RIGHT_BOTTOM' },
    groupControl: { x: 20, y: 40, type: 'RIGHT_TOP' }
  }
  options: any = {
    speed: 500,
    startDate: new Date(),
    endDate: new Date(),
    timeUnitControl: true, //是否显示时/天切换控件
    timeUnit: 'hour', // 默认按天还是按小时
    isShowHour: true // 按小时是否可点
  }
  isShowPlay = true

  async created() {
    this.calTableHeight()
    window.addEventListener('resize', this.calTableHeight)
    await this.indexConfig()
  }
  activated() {
    if (!this.isSuccessLoad) {
      this.mapLoaded = false
    }
    this.isTimeout()
  }
  destroyed() {
    window.removeEventListener('resize', this.calTableHeight)
  }
  onAnimate(val: any) {
    //时间轴动画每次结束回调
    if (this.isShowPlay) {
      const { hour, day, mon, year } = val
      this.animateTime = this.getTimes(year + '年' + mon + '月' + day + '日' + hour + '时')
      this.heating = this.dateAreasMap[this.formatTime(this.animateTime)]
    }
  }

  onClickChangeEnd(val: any) {
    //点击后回调
    //获取点击的时间
    if (this.isShowPlay) {
      const { hour, day, mon, year } = val
      this.animateTime = this.getTimes(year + '年' + mon + '月' + day + '日' + hour + '时')
      const time = this.span === 'day' ? formatDateStart(this.animateTime) : this.animateTime
      this.heating = this.dateAreasMap[this.formatTime(time)]
    }
  }

  onTimeUnit(timeUnit: string) {
    if (this.isShowPlay) {
      this.span = timeUnit
      this.getData()
    }
  }
  onChangeSpeed(speed: number) {
    this.speed = speed
  }

  isTimeout() {
    if (!this.mapLoaded) {
      this.showMap = true
      this.timer = setTimeout(() => {
        if (!this.isSuccessLoad) {
          this.mapLoaded = true
          this.showMap = false
          this.message = this.$message({
            type: 'error',
            message: '地图加载失败，点击加载按钮重新加载。',
            customClass: 'is-underlying',
            duration: 0,
            showClose: true,
            clickButton: '加载',
            onClick: async () => {
              this.mapLoaded = false
              this.isTimeout()
              this.message.close()
            },
            createElement: this.$createElement
          })
        }
      }, 10000)
    }
  }
  loadComplete() {
    this.mapLoaded = true
    this.isSuccessLoad = true
  }

  async changeTab(index: number) {
    this.tabIndex = index
    if (this.children.length === 0) {
      await this.indexConfig()
    }
    let legendTitle = ''
    if (this.tabIndex === 0) {
      this.groupLine = []
      this.residenceTime = []
      legendTitle = '区域人数/区域容量'
      this.isShowPlay = true
      await this.getThermal()
    }
    if (this.tabIndex === 1) {
      this.groupLine = []
      this.heating = []
      this.isShowPlay = false
      legendTitle = '区域平均停留时间/所有区域平均停留时间最大值'
      await this.getResidenceTime()
    }
    if (this.tabIndex === 2) {
      this.residenceTime = []
      this.heating = []
      this.isShowPlay = false
      legendTitle = '选择此路线的人数/所有路线中的最大人数'
      await this.getInsight()
    }
    if (this.fengmapNode) this.fengmapNode.changeTab(legendTitle)
  }

  async confirmForm(val: AnyObj = {}) {
    if (this.children.length === 0) {
      await this.indexConfig()
    }
    if (Object.keys(val).length > 0) {
      this.form = val
      const end = moment(this.form.endDate).endOf('day').valueOf()
      const start = moment(this.form.startDate).startOf('day').valueOf()
      this.intervalDays = Math.ceil((end - start) / (24 * 60 * 60 * 1000))
      if (this.intervalDays > 7) {
        this.span = 'day'
        this.isShowHour = false
      } else {
        this.isShowHour = true
      }
    }
    if (this.tabIndex === 0) {
      await this.getThermal()
    }
    if (this.tabIndex === 1) {
      await this.getResidenceTime()
    }
    if (this.tabIndex === 2) {
      await this.getInsight()
    }
  }
  async getInsight() {
    if (this.children.length > 0) {
      this.apiLoaded = false
      const sumData: any[] = []
      const params = {
        areaIds: this.children,
        startTime: formatDateStart(this.form.startDate),
        endTime: formatDateEnd(this.form.endDate),
        identity: this.form.identity || null
      }
      const { data } = await this.$api.base.getInsightFlow(params)
      if (data && data.data && data.data.length > 0) {
        const res = data.data
        res.forEach((item: any) => {
          let flag = false
          sumData.map(obj => {
            if (item.startArea === obj.startArea && item.endArea === obj.endArea) {
              flag = true
              this.computeDate(obj, item, 'insight')
            }
          })
          if (!flag) {
            sumData.push(item)
          }
        })
        let h = false
        sumData.map(obj => {
          obj.originTotal = obj.total
          obj.total = this.filterAgeGender(obj, 'insight')
          if (obj.total > 0) {
            h = true
          }
        })
        if (!h) {
          this.$message({
            message: '暂无群体动线',
            type: 'warning'
          })
        }
        this.apiLoaded = true
        this.groupLine = sumData
      } else {
        this.apiLoaded = true
        this.groupLine = []
        this.$message({
          message: '暂无群体动线',
          type: 'warning'
        })
      }
    }
  }
  async getResidenceTime() {
    if (this.children.length > 0) {
      this.apiLoaded = false
      let staySum: any[] = []

      const params = {
        areaIds: this.children,
        startTime: formatDateStart(this.form.startDate),
        endTime: formatDateEnd(this.form.endDate),
        span: 'day',
        type: 'person',
        identity: this.form.identity || null
      }
      const { data } = await this.$api.base.getResidenceTime(params)
      if (data && data.data && data.data.length > 0) {
        const res = data.data
        this.children.forEach(item => {
          const list = res.filter((i: any) => {
            return i.areaId === item
          })
          let stayList: AnyObj = {
            genderAge: {
              male: {
                child: { personCount: 0, timeSum: 0 },
                teen: { personCount: 0, timeSum: 0 },
                youth: { personCount: 0, timeSum: 0 },
                adult: { personCount: 0, timeSum: 0 },
                midlife: { personCount: 0, timeSum: 0 },
                old: { personCount: 0, timeSum: 0 }
              },
              female: {
                child: { personCount: 0, timeSum: 0 },
                teen: { personCount: 0, timeSum: 0 },
                youth: { personCount: 0, timeSum: 0 },
                adult: { personCount: 0, timeSum: 0 },
                midlife: { personCount: 0, timeSum: 0 },
                old: { personCount: 0, timeSum: 0 }
              }
            },
            timeSum: 0,
            personCount: 0,
            areaId: '',
            areaName: ''
          }
          list.forEach((obj: any, index: number) => {
            stayList = this.computeDate(stayList, list[index], 'stay')
            stayList.areaId = item
            stayList.areaName = obj.areaName
            stayList.timeSum = stayList.timeSum + obj.timeSum
            stayList.personCount = stayList.personCount + obj.personCount
          })
          staySum = staySum.concat(stayList)
        })
        this.residenceTime = staySum.map(obj => {
          const value = this.filterAgeGender(obj, 'stay')
          obj.timeSum = value.timeSum
          obj.personCount = value.personCount
          obj.total =
            value.personCount === 0 ? 0 : (value.timeSum / value.personCount / 60).toFixed(2)
          obj.text =
            value.personCount === 0
              ? 0
              : getDuration(value.timeSum / value.personCount, timeType.TWO_SECTIONS)
          return obj
        })
      }
      this.apiLoaded = true
    }
  }
  getThermal() {
    if (this.children.length > 0) {
      const startDate = formatDateStart(this.form.startDate)
      const endDate = formatDateEnd(this.form.endDate)
      this.options = {
        startDate,
        endDate,
        speed: this.speed,
        timeUnit: this.span,
        isShowHour: this.isShowHour
      }
      this.animateTime = null
      this.getData()
    }
  }
  getTimes(time: any) {
    // 获取时间戳
    return moment(time, 'YYYY-MM-DD HH:mm:ss').valueOf()
  }
  formatTime(time: Date | number) {
    return moment(time).format('YYYY/MM/DD/HH')
  }
  async getData() {
    let sumFolw: any[] = []
    this.apiLoaded = false

    const params = {
      areaIds: this.children,
      startTime: formatDateStart(this.form.startDate),
      endTime: formatDateEnd(this.form.endDate),
      span: this.span,
      type: 'person',
      identity: this.form.identity || null
    }
    const { data } = await this.$api.base.getStatisticsAreas(params)
    if (data && data.data && data.data.length > 0) {
      const res = data.data
      const time = Array.from(new Set(res.map((i: any) => i.time)))
      time.forEach(item => {
        this.children.forEach(area => {
          const list = res.filter((i: any) => {
            return i.time === item && i.areaId === area
          })
          let sumData: AnyObj = {
            genderAge: {
              male: {
                child: 0,
                teen: 0,
                youth: 0,
                adult: 0,
                midlife: 0,
                old: 0
              },
              female: {
                child: 0,
                teen: 0,
                youth: 0,
                adult: 0,
                midlife: 0,
                old: 0
              }
            },
            total: 0,
            time: ''
          }
          list.forEach((obj: any, index: number) => {
            sumData = this.computeDate(sumData, list[index], 'data')
            sumData.time = item
            sumData.areaId = area
          })
          sumFolw = sumFolw.concat(sumData)
        })
      })
      sumFolw.map(obj => {
        obj.originTotal = obj.total
        obj.total = this.filterAgeGender(obj, 'data')
      })
      this.thermalData = sumFolw
    }
    this.apiLoaded = true
    this.getHeating()
  }
  filterAgeGender(val: AnyObj, type: string) {
    const gender = this.form.gender
      ? this.form.gender === 'all'
        ? ['male', 'female']
        : [this.form.gender]
      : ['male', 'female']
    const age =
      this.form.age && this.form.age.length > 0
        ? this.form.age
        : ['child', 'teen', 'youth', 'adult', 'midlife', 'old']
    let sum: any
    if (type === 'stay') {
      sum = {
        timeSum: 0,
        personCount: 0
      }
      gender.forEach(m => {
        age.forEach((n: any) => {
          sum.timeSum = sum.timeSum + val.genderAge[m][n].timeSum
          sum.personCount = sum.personCount + val.genderAge[m][n].personCount
        })
      })
    } else {
      sum = 0
      gender.forEach(m => {
        age.forEach((n: any) => {
          sum = sum + val.genderAge[m][n]
        })
      })
    }
    return sum
  }
  computeDate(sumData: AnyObj, res: AnyObj, type: string) {
    const age = ['child', 'teen', 'youth', 'adult', 'midlife', 'old']
    const gender = ['male', 'female']
    const stay = ['timeSum', 'personCount']
    if (type === 'stay') {
      gender.forEach(m => {
        age.forEach(n => {
          stay.forEach(i => {
            if (res.genderAge[m]) {
              sumData.genderAge[m][n][i] = sumData.genderAge[m][n][i] + res.genderAge[m][n][i]
            } else {
              sumData.genderAge[m][n][i] = sumData.genderAge[m][n][i] + res.genderAge.femal[n][i]
            }
          })
        })
      })
    } else {
      sumData.total = sumData.total + res.total
      gender.forEach(m => {
        age.forEach(n => {
          if (res.genderAge[m]) {
            sumData.genderAge[m][n] = sumData.genderAge[m][n] + res.genderAge[m][n]
          } else {
            sumData.genderAge[m][n] = sumData.genderAge[m][n] + res.genderAge.femal[n]
          }
        })
      })
    }
    return sumData
  }
  dedupe(array: any[]) {
    return Array.from(new Set(array))
  }
  getHeating() {
    this.dateAreasMap = {}
    if (this.thermalData.length > 0) {
      const time = this.dedupe(this.thermalData.map(i => i.time))
      time.forEach(item => {
        const list = this.thermalData.filter(obj => {
          return obj.time === item
        })
        this.dateAreasMap[this.formatTime(item)] = list
      })
    }
    let time = null
    time = formatDateStart(this.form.startDate)
    this.heating = this.dateAreasMap[this.formatTime(time)]
  }
  async indexConfig() {
    const { config } = this.$store.state
    if (config && config.children) {
      this.children = config.children.map((i: any) => i.id)
    }
    if (this.timer) clearTimeout(this.timer)
  }
  calTableHeight() {
    this.$nextTick(() => {
      this.mapHeight = window.innerHeight - 94
    })
  }
}
