























































import { Vue, Component } from 'vue-property-decorator'

@Component({ name: 'index' })
export default class Screen extends Vue {
  ageOptions = [
    { value: 'child', label: '0-17' },
    { value: 'teen', label: '18-25' },
    { value: 'youth', label: '26-35' },
    { value: 'adult', label: '36-45' },
    { value: 'midlife', label: '46-55' },
    { value: 'old', label: '56以上' }
  ]
  filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')],
    gender: 'all',
    age: []
  }
  checkAllAges = false
  isAgeIndeterminate = false

  async mounted() {
    this.handleFilterChange()
  }

  handleCheckAllAgesChange(checked: boolean) {
    this.filterData.age = checked ? this.ageOptions.map(({ value }) => value) : []
    this.isAgeIndeterminate = false
  }

  handleCheckedAgesChange(value: string[]) {
    const checkedCount = value.length
    this.checkAllAges = checkedCount === this.ageOptions.length
    this.isAgeIndeterminate = checkedCount > 0 && checkedCount < this.ageOptions.length
  }

  handleFilterChange() {
    const { dateRange, age, gender, identity } = this.filterData
    const [startDate, endDate] = dateRange

    this.$emit('confirm-form', { startDate, endDate, age, gender, identity })
  }
}
