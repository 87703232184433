


































































































/**
 * 关于时间播放器timePlay的说明
 * @displayName timePlay组件
 */
import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import moment from 'moment'
@Component({
  name: 'timePlay'
})
export default class TimePlay extends Vue {
  @Ref('timeProgressBox') readonly timeProgressBox: HTMLDivElement
  @Ref('timeProgressInner') readonly timeProgressInner: HTMLDivElement
  @Ref('timeProgress') readonly timeProgress: HTMLDivElement
  /**
   * 播放器的默认配置
   * @values {speed: 500,startDate:'',endDate:'',timeUnit:'hour',isShowHour:true}
   */
  @Prop({ default: () => {} }) options: AnyObj
  defaultOptions = {
    speedConfig: {
      // 速度配置
      慢: 5000,
      中: 3000,
      快: 500
    },
    speed: 500, // 默认速度
    startDate: 20180701,
    endDate: 20180831,
    timeUnit: 'hour', // 播放单位
    isShowHour: true // 按小时是否可点
  }
  timeUnit = 'hour' // 播放单位
  speed = 500 // 播放速度
  playAction = 'play' // 播放or暂停
  timer: any = null //动画定时器
  speedIndex: number | null = null
  playWidth = 0 //时间轴长度
  boxLeft = 0 // 播放容器距离窗口左边的距离
  translate = 0 //时间轴向左的位移长度
  dis = 108 // 运动每格长度
  disHour = 108 / 24 //小时单位移动距离
  currX = 0 //临时记录X轴位移
  tempDay = {} //临时记录时间
  indexHover = 0 //临时索引
  hover = 0 //临时索引
  index = 0
  unitMap = {
    '1': 'day',
    '2': 'hour'
  }
  currDay: AnyObj = {
    year: '',
    month: '',
    day: '',
    hour: ''
  }
  isShowAniPopup = false //是否展示播放时的popup
  isShowClickPopup = false // 是否展示点击时的popup
  isShowHoverPopup = false // 是否展示hover时的popup
  hoverPopupLeft = 0
  timeList: any[] = []
  isCrossYear = false
  nextBtn = false
  prevBtn = false
  @Watch('options', { deep: true, immediate: true })
  watchOptions() {
    {
      if (this.timer) clearInterval(this.timer)
      this.playAction = 'play'
      Object.assign(this.defaultOptions, this.options) //基本配置
      this.timeUnit = this.defaultOptions.timeUnit
      this.speed = this.defaultOptions.speed
      this.speedIndex = this.speed
      this.$nextTick(() => {
        this.boxLeft = this.timeProgressBox.getBoundingClientRect().left + 29 //加29是因为.timeProgress-inner设置了margin-left:29px;
        this.fillDate()
        this.initDate()
      })
    }
  }
  destroyed() {
    clearInterval(this.timer)
  }
  deactivated() {
    this.stopPlay()
  }
  changeSpeed(val: number) {
    let flag = false
    if (this.playAction === 'pause') {
      flag = true
    }
    this.speedIndex = val
    this.speed = val
    this.stopPlay()
    if (flag) {
      this.playAction = 'pause'
      this.startPlay()
    }
    /**
     * 当改变播放器的速度时触发
     * @event onChangeSpeed
     * @property 播放器的速度
     */
    this.$emit('onChangeSpeed', val)
  }
  playClick() {
    if (this.playAction === 'play') {
      this.playAction = 'pause'
    } else {
      this.playAction = 'play'
    }
    if (this.playAction === 'pause') {
      if (this.playWidth >= this.timeProgress.clientWidth) {
        //如果播放完成再点击播放按钮从开始播放
        this.initDate()
      } else {
        this.startPlay()
      }
    } else {
      this.stopPlay()
    }
  }
  fillDate() {
    this.timeList = []
    const start = new Date(this.defaultOptions.startDate)
    const end = new Date(this.defaultOptions.endDate)
    let startYear = start.getFullYear(),
      startMonth = start.getMonth() + 1,
      startDay = start.getDate()

    const endYear = end.getFullYear(),
      endMonth = end.getMonth() + 1,
      endDay = end.getDate()
    this.isCrossYear = startYear !== endYear
    while (this.compareTime(startDay, endDay, startMonth, endMonth, startYear, endYear)) {
      if (startDay > this.monthCount(startMonth, startYear)) {
        startDay = 1
        startMonth++
      }
      if (startMonth > 12) {
        startMonth = 1
        startYear++
      }
      const obj = {
        year: startYear,
        month: startMonth,
        day: startDay
      }
      this.timeList.push(obj)
      startDay++
    }
  }
  compareTime(
    startDay: any,
    endDay: any,
    startMonth: any,
    endMonth: any,
    startYear: any,
    endYear: any
  ) {
    let flag = false
    if (startYear < endYear) {
      flag = true
    } else if (startYear === endYear) {
      if (startMonth < endMonth) {
        flag = true
      } else if (startMonth === endMonth) {
        if (startDay <= endDay) {
          flag = true
        }
      }
    }
    return flag
  }
  monthCount(month: number, year: number) {
    let num = 0
    if (
      month === 1 ||
      month === 3 ||
      month === 5 ||
      month === 7 ||
      month === 8 ||
      month === 10 ||
      month === 12
    ) {
      num = 31
    } else if (month === 4 || month === 6 || month === 9 || month === 11) {
      num = 30
    } else if (month === 2) {
      if (moment([year]).isLeapYear()) {
        num = 29
      } else {
        num = 28
      }
    }
    return num
  }
  stopPlay() {
    // 暂停播放
    this.playAction = 'play'
    clearInterval(this.timer)
  }
  startPlay() {
    this.isShowClickPopup = false
    this.isShowAniPopup = true
    this.timer = setInterval(() => {
      const tempDate = this.currDay
      if (this.reachEnd()) {
        this.halfPageNext()
      }
      if (this.timeUnit === 'hour') {
        const readWidth = Math.floor(this.playWidth / this.disHour) * this.disHour
        this.playWidth = readWidth + this.disHour
        if (this.playWidth < this.timeProgress.clientWidth) {
          this.currDay.hour = this.currDay.hour + 1
          if (this.currDay.hour % 24 === 0) {
            this.index++
            this.currDay = {
              year: this.timeList[this.index].year,
              mon: this.timeList[this.index].month,
              day: this.timeList[this.index].day,
              hour: 0
            }
          }
        }
      } else {
        this.index++
        const readWidth = Math.floor(this.playWidth / this.dis) * this.dis
        this.playWidth = readWidth + this.dis
        if (this.playWidth < this.timeProgress.clientWidth) {
          this.currDay = {
            year: this.timeList[this.index].year,
            mon: this.timeList[this.index].month,
            day: this.timeList[this.index].day,
            hour: 0
          }
        }
      }
      if (this.playWidth >= this.timeProgress.clientWidth) {
        this.playWidth = this.timeProgress.clientWidth
        this.currDay = tempDate
        this.stopPlay()
      } else {
        /**
         * 当播放器播放过程中触发
         * @event onAnimate
         * @property 当前的时间刻度
         */
        this.$emit('onAnimate', this.currDay)
      }
    }, this.speed)
  }
  reachEnd() {
    const disRight = this.timeProgressBox.clientWidth - (this.playWidth - this.translate)
    if (disRight <= 108 + 29) {
      return true
    } else {
      return false
    }
  }
  halfPageNext() {
    this.pageNext('half')
  }
  initDate() {
    // 初始化日期
    const currDate = new Date(this.defaultOptions.startDate)
    const year = currDate.getFullYear()
    const month = currDate.getMonth() + 1
    const day = currDate.getDate()
    const hour = currDate.getHours()
    this.isShowClickPopup = false
    this.isShowAniPopup = true
    this.currDay = {
      year: year,
      mon: month,
      day: day,
      hour: hour
    }
    /**
     * 当播放器播放过程中触发
     * @event onAnimate
     * @property 当前的时间刻度
     */
    this.$emit('onAnimate', this.currDay)
    for (let i = 0, len = this.timeList.length; i < len; i++) {
      const m = this.timeList[i].month
      const d = this.timeList[i].day
      if (month === m && day === d) {
        this.index = i
        this.playWidth = this.dis * i + (this.dis / 24) * hour
        this.$nextTick(() => {
          this.progressAni()
        })
        break
      }
    }
    if (this.playAction === 'pause') {
      //初始化后如果正在播放就开始播放
      this.startPlay()
    }
  }
  progressAni() {
    // 进度条动画
    const pageWidth = this.timeProgressBox.clientWidth,
      conWidth = this.timeProgressInner.clientWidth,
      pageNum = Math.floor(this.playWidth / pageWidth)

    let leftDis = pageNum * pageWidth
    if (pageNum !== 0) {
      if (pageWidth - this.playWidth + leftDis < this.dis) {
        leftDis = leftDis + pageWidth / 2
      }

      if (leftDis + pageWidth > conWidth + 40) {
        leftDis = conWidth - pageWidth + 40
        this.prevBtn = false
      }
      if (this.playWidth !== 0) {
        if (this.playWidth - leftDis < this.dis) {
          leftDis = leftDis - pageWidth / 2
        }
      }
    }
    if (pageWidth > conWidth + 40) {
      this.nextBtn = false
    } else {
      this.nextBtn = true
    }
    this.translate = leftDis
  }
  changeTimeUnit(index: number) {
    if (index === 1 || (this.defaultOptions.isShowHour && index === 2)) {
      this.timeUnit = this.unitMap[index]
      /**
       * 当切换播放器的时间单位（天/时）时触发
       * @event onTimeUnit
       * @property 当前的时间单位
       */
      this.$emit('onTimeUnit', this.timeUnit)
      this.stopPlay()
      this.initDate()
    }
  }
  pageNext(type: string) {
    this.isShowClickPopup = false
    this.isShowAniPopup = true
    this.prevBtn = true
    const pageWidth = this.timeProgressBox.clientWidth,
      conWidth = this.timeProgressInner.clientWidth
    const width = type === 'half' ? pageWidth / 2 : pageWidth
    this.translate += width
    if (this.translate + pageWidth > conWidth + 40) {
      this.translate = conWidth - pageWidth + 40
      this.nextBtn = false
    }
  }
  pagePrev() {
    this.nextBtn = true
    this.isShowClickPopup = false
    this.isShowAniPopup = true
    this.translate -= this.timeProgressBox.clientWidth
    if (this.translate < 0) {
      this.translate = 0
      this.prevBtn = false
    }
  }
  clickPopup(event: any) {
    const x = event.clientX
    const dayIndex = Math.floor((x + this.translate - this.boxLeft) / this.dis)
    const len = this.timeProgress.clientWidth / this.dis
    if (dayIndex >= 0 && dayIndex <= len - 1) {
      let flag = false
      if (this.playAction === 'pause') {
        this.stopPlay()
        flag = true
      }

      this.isShowAniPopup = false
      this.isShowClickPopup = true

      this.currX = x - this.boxLeft + 29
      this.playWidth = x + this.translate - this.boxLeft
      this.currDay = this.tempDay
      this.index = this.indexHover
      /**
       * 当点击播放器的进度条时触发
       * @event onClickEnd
       * @property 当前的时间刻度
       */
      this.$emit('onClickEnd', this.currDay)
      if (flag) {
        this.playAction = 'pause'
        this.startPlay()
      }
    }
  }
  hoverPopup(event: any) {
    const x = event.clientX
    const dayIndex = Math.floor((x + this.translate - this.boxLeft) / this.dis)
    const len = this.timeProgress.clientWidth / this.dis
    if (dayIndex >= 0 && dayIndex <= len - 1) {
      this.indexHover = dayIndex
      this.tempDay = {
        year: this.timeList[dayIndex].year,
        mon: this.timeList[dayIndex].month,
        day: this.timeList[dayIndex].day,
        hour: Math.floor(((x + this.translate - this.boxLeft) % this.dis) / this.disHour)
      }
      this.isShowHoverPopup = true
      this.hoverPopupLeft = x - this.boxLeft + 29
    }
  }
  leavePopup() {
    this.isShowHoverPopup = false
  }
}
